import React from "react";

import './Loading.css';

const Loading = () => (
    <div className="Loading">
            Loading...
    </div>
)

export default Loading;